import styled from "styled-components";
import { COLORS } from "./styles"

import iconMirror from '../assets/icons/icon-mirror.png'

export const MainSubtitle = styled.h2`
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 3px;
    line-height: 20px;
    color: ${props => props.color || COLORS.black};

    @media (max-width: 768px) {
        font-size: 12px;
    }

`;

export const MainTitle = styled.h1`
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: normal;
    color: ${props => props.color || COLORS.black};
    position: relative;
    width: inherit;
    text-align: ${props => props.textAlign || "inherit"};

    &.span{
        font-weight: 600
    }

    ${props => props.useIcon &&
        `::before{
            content: "";
            background: url(${iconMirror});
            position: absolute;
            display: block;
            height: 46px;
            width: 72px;
            top: 0px;
            left: 260px;
        }`
    }

    @media (max-width: 768px) {
        font-size: 35px;

        ::before{
            display:none;
        }
    }
`;

export const GeneralRegularText = styled.h1`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: ${COLORS.white};

    @media (max-width: 768px) {
    
    }
`;

export const GeneralButton = styled.a`
    width: 250px;
    height: 45px;
    display: flex;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.06em;
    color: ${COLORS.white};
    background: ${COLORS.primary};
    box-shadow: 12px 12px 1px rgba(150, 180, 196, 0.81);
    align-items:center;
    justify-content: center;
    text-transform: uppercase;
    transition: 0.5s all;
    cursor: pointer;

    :hover{
        transform: scale(1.05);
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }

`;


export const GeneralTitleBlue = styled.h2`
    font-weight: 300;
    font-size: 35px;
    line-height: 38px;
    color: ${COLORS.white};
    text-transform: uppercase;

    strong{
        font-weight: 600;
    }

    ${props => props.useBorder &&
        `::after{
            content: "";
            position: relative;
            display: block;
            border-bottom: 2px solid ${COLORS.white};
            border-bottom-style: dashed;
            width: 120px;
            top: 30px;
        }`
    }

    @media (max-width: 768px) {
        br{
            display:none;
        }
    }   
   
`;

export const GeneralLabel = styled.label`
    font-weight: 600;
    font-size: 13px;
    line-height: 25px;
    color: ${COLORS.white};
    text-align: left;
`;

export const GeneralInput = styled.input`
    background: ${COLORS.white};
    border-radius: 6px;
    border: none;
    height: 35px;
    font-size: 12px;
    width: 100%;
    padding: 0px 10px;
`;

export const GeneralFormButton = styled.button`
    width: 115px;
    height: 32px;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.06em;
    color: ${COLORS.white};
    text-transform: uppercase; 
    background: transparent;
    border: 1px solid ${COLORS.white};
    border-radius: 6px;
    cursor: pointer;
    transition: 0.5s all;

    :hover{
        transform: scale(1.1)
    }
`;

export const GeneralFormGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const GeneralCheckFormGroup = styled(GeneralFormGroup)`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 15px;
`;


export const GeneralTextArea = styled.textarea`
    background: ${COLORS.white};
    border-radius: 6px;
    border: none;
    height: 35px;
    font-size: 12px;
    width: 100%;
    padding: 10px 10px;
    height: 82px;
    resize: none;
`;

export const GeneralCheckBox = styled.input`
    width: 36px;
    height: auto;
    background: ${COLORS.white};
    border-radius: 2px;
    border: none;
    display: block;
`;

export const GeneralCheckLabel = styled.label`
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: ${COLORS.white};
    cursor: pointer;
`;

export const GeneralFormError = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${COLORS.white};
    cursor: pointer;
    margin-top: 5px;
`;