import React from 'react'

import facebookIcon from '../../assets/icons/facebook.png'
import instagramIcon from '../../assets/icons/instagram.png'
import logoFooter from '../../assets/images/main-logo.png'
import logoOlivaTech from '../../assets/icons/oliva-tech.png'

import {
  FooterContent, FooterContainer, FooterLogo,
  FooterLabel, FooterText, FooterBox, FooterSocialBox,
  LogoOliva, AsideButton
} from './styles'

import whatsappIcon from '../../assets/icons/whatsapp.png'

function Footer() {
  return (
    <FooterContent>
      <div className='center flex'>
        <FooterContainer>
          <FooterLogo src={logoFooter} />
          <FooterBox>
            <FooterLabel>Contato</FooterLabel>
            <FooterText>Telefone:. <a href="tel:24999971961">(24) 99997-1961</a></FooterText>
            <FooterText>Volta Redonda - RJ</FooterText>
          </FooterBox>
          <FooterBox>
            <FooterLabel>Redes Sociais</FooterLabel>
            <FooterSocialBox>
              <a href="#">
                <img src={instagramIcon} alt="Icone instagram" />
              </a>
              <a href="#">
                <img src={facebookIcon} alt="Icone Facebook" />
              </a>
            </FooterSocialBox>
          </FooterBox>

          <FooterBox>
            <FooterLabel>Desenvolvido por</FooterLabel>
            <a href="https://linktree.olivatech.dev/" target="_blank">
              <LogoOliva src={logoOlivaTech} />
            </a>
          </FooterBox>
        </FooterContainer>
      </div>

      <AsideButton href='https://wa.me/5524999971961?text=Ol%C3%A1+Janderson%2C+vim+do+seu+site+e+gostaria+de+um+or%C3%A7amento.' target="_blank">
        <img src={whatsappIcon} alt="Icone Whatsapp" />
      </AsideButton>
    </FooterContent>
  )
}

export default Footer