import axios from "axios"

const api = axios.create({
    baseURL: "https://landing.api.olivatech.dev/api/"
})

export default api


const contact_endpoint = "/contact/"

export function postContactForm(data){
    return api.post(contact_endpoint, data);
}
