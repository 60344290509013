import React from 'react'

import Header from '../../components/header'
import Footer from '../../components/footer'
import Banner from '../../components/banner'
import Testimonials from '../../components/testimonials'
import Portfolio from '../../components/portfolio'
import Services from '../../components/services'
import PortfolioBudget from '../../components/portfolioBudget'
import FAQ from '../../components/FAQ'
import BudgetForm from '../../components/budgetForm'
import "lightgallery.js/dist/css/lightgallery.css";

export default function Home() {
    return (
        <div>
            <Header />
            <Banner />
            <Services />
            <Portfolio />
            <PortfolioBudget />
            <Testimonials />
            <FAQ />
            <BudgetForm />
            <Footer />
        </div>
    )
}
