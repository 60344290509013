import styled from 'styled-components'
import { COLORS } from '../../global/styles'


export const FooterContent = styled.div`
    height: 228px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        height: 100%;
        padding: 40px 0px;
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction:column;
        align-items: center;
        height: 100%;
        margin-bottom: 80px;
    }
`;

export const FooterLogo = styled.img`
    width: 221px;
    height: 58px;
    
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
`;

export const FooterBox = styled.div`
    @media (max-width: 768px) {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
`;

export const FooterText = styled.div`
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
`;

export const FooterLabel = styled.div`
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: ${COLORS.black};
    margin-bottom: 10px;
`;

export const FooterSocialBox = styled.div`
    display: flex;
    gap:10px;

    img{    
        width: 30px;
        height: 30px;
    }
`;
export const LogoOliva = styled.img`
    width: 60px;
`;

export const AsideButton = styled.a`
    position: fixed;
    bottom: 30px;
    right: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .5s all;

    :hover{
        transform: scale(1.1);
    }

    img{
        width: 64px;
        height: 64px;
    }
`;

