import React from 'react'

import { MainSubtitle, GeneralRegularText } from '../../global/components'

import {
  TestContent, TestDate,
  TestImage, TestTitle, TestimonialBox, TestimonialContent,
  TestTitleBlue
} from './styles'

import testImage1 from '../../assets/images/testimonials/testmonial1.png'

function Testimonials() {
  return (
    <TestimonialContent>
      <div className="center-inner text-center">
        <MainSubtitle color="#fff">E os clientes,</MainSubtitle>
        <TestTitleBlue>O que estão dizendo?</TestTitleBlue>
      </div>

      <div className="center-inner flex flex-column">
        <TestimonialBox>
          <TestImage>
            <img src={testImage1} alt="Imagem Larha e Raphael" />
          </TestImage>
          <TestContent>
            <TestTitle>Larha & Raphael</TestTitle>
            <TestDate>10/10/2022</TestDate>
            <GeneralRegularText>
              Fizemos toda a obra da nossa nova casa com o Janderson e ele foi muito atencioso e justo,
              explicando para nós cada etapa, desde a compra do vidro até a montagem. Gostamos bastante do atendimento 
              e do serviço dele.
            </GeneralRegularText>

          </TestContent>
        </TestimonialBox>
      </div>
    </TestimonialContent>
  )
}

export default Testimonials