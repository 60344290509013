export const items = [
    {
        id: "group1",
        thumbnail: "/assets/portfolio/larha-e-raphael/image4.png",
        image: "/assets/portfolio/larha-e-raphael/image4.png",
        title: "Portas e Janelas",
        client: "Larha e Raphael",
        images:[
            "/assets/portfolio/larha-e-raphael/image2.png",
            "/assets/portfolio/larha-e-raphael/image4.png",
            "/assets/portfolio/larha-e-raphael/image6.png",
        ]
    },
    {
        id: "group2",
        thumbnail: "/assets/portfolio/edna/image1.jpeg",
        image: "/assets/portfolio/edna/image1.jpeg",
        title: "Guarda-corpo, Báscula e Box",
        client: "Edna",
        images:[
            "/assets/portfolio/edna/image2.jpeg",
            "/assets/portfolio/edna/image3.jpeg",
            "/assets/portfolio/edna/image4.jpeg",
        ]
    },
    {
        id: "group3",
        thumbnail: "/assets/portfolio/nafil/image1.jpeg",
        image: "/assets/portfolio/nafil/image1.jpeg",
        title: "Instalação de vidros no balcão",
        client: "Nafil Auto Peças",
        images:[
            "/assets/portfolio/nafil/image2.jpeg",
            "/assets/portfolio/nafil/image3.jpeg"
        ]
    },
    {
        id: "group4",
        thumbnail: "/assets/portfolio/diego/image1.jpeg",
        image: "/assets/portfolio/diego/image1.jpeg",
        title: "Portas, Janelas e Báscula",
        client: "Oficina Mecânica: Diego",
        images:[
            "/assets/portfolio/diego/image2.jpeg",
            "/assets/portfolio/diego/image3.jpeg",
            "/assets/portfolio/diego/image4.jpeg",
            "/assets/portfolio/diego/image5.jpeg",
            "/assets/portfolio/diego/image6.jpeg",
            "/assets/portfolio/diego/image7.jpeg",
            "/assets/portfolio/diego/image8.jpeg",
            "/assets/portfolio/diego/image9.jpeg",
            "/assets/portfolio/diego/image10.jpeg",
        ]
    },
    {
        id: "group5",
        thumbnail: "/assets/portfolio/luciene/image1.jpeg",
        image: "/assets/portfolio/luciene/image1.jpeg",
        title: "Janelas e Portas",
        client: "Luciene",
        images:[
            "/assets/portfolio/luciene/image2.jpeg",
            "/assets/portfolio/luciene/image3.jpeg",
            "/assets/portfolio/luciene/image4.jpeg",
            "/assets/portfolio/luciene/image5.jpeg",
        ]
    },
    {
        id: "group6",
        thumbnail: "/assets/portfolio/amanda/image1.jpeg",
        image: "/assets/portfolio/amanda/image1.jpeg",
        title: "Espelho e Mesa",
        client: "Amanda",
        images:[
            "/assets/portfolio/amanda/image2.jpeg",
            "/assets/portfolio/amanda/image3.jpeg",
        ]
    },
    {
        id: "group7",
        thumbnail: "/assets/portfolio/padrejosimo/image1.jpeg",
        image: "/assets/portfolio/padrejosimo/image1.jpeg",
        title: "Janelas e Porta",
        client: "Padre Josimo",
        images:[
            "/assets/portfolio/padrejosimo/image2.jpeg",
        ]
    }
]