import styled from "styled-components";
import { COLORS } from '../../global/styles'


export const BudgetForm = styled.form`
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 12px;
    width: 50%;

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 30px;
    }
`;

export const PortBudgetContent = styled.div`
    padding-bottom: 100px;
`;

export const BudgetFormInner = styled.div`
    display: flex;
    gap: 11px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const BudgetInner = styled.div`
    background: ${COLORS.generalGradient};
    height: 238px;
    display: flex;
    align-items: center;
    padding: 56px;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        height:100%;
        padding: 35px;
    }
`;

export const BudgetTitle = styled.h2`
    font-weight: 300;
    font-size: 35px;
    line-height: 40px;
    color: ${COLORS.white};
    text-transform: uppercase;

    strong{
        font-weight: 600;
        font-size: 42px;
    }

    ${props => props.useBorder &&
        `::after{
            content: "";
            position: relative;
            display: block;
            border-bottom: 2px solid ${COLORS.white};
            border-bottom-style: dashed;
            width: 120px;
            top: 30px;
        }`
    }

    @media (max-width: 768px) {
        font-size: 35px;
        line-height: 34px;

        br{
            display:none;
        }
        
        strong{
            font-size: 35px;
        }
    }
   
`;