import React from 'react'

import { GeneralButton } from '../../global/components'
import {
  BannerContent, BannerLeftContainer, BannerTitle, BannerNumbersContainer,
  Number, BannerRightContainer, CardStory, CardStoryTap, CardStoryControls
} from './styles'

import storyImage from '../../assets/images/banner-conheca.png'
import playIcon from '../../assets/icons/tap.png'

function Services() {
  return (
    <BannerContent>
      <div className='center flex space-around'>
        <BannerLeftContainer>
          <BannerTitle>TRANSPARÊNCIA E QUALIDADE<br/>É A NOSSA ESPECIALIDADE</BannerTitle>
          <BannerNumbersContainer>
            <Number>
              <span>+50</span>
              <p> Obras <br /> concluídas</p>
            </Number>
            <Number>
              <span>+6</span>
              <p>Anos de <br /> profissão</p>
            </Number>
          </BannerNumbersContainer>

          <GeneralButton href="/#inscreva-se">Faça um orçamento</GeneralButton>
        </BannerLeftContainer>

        <BannerRightContainer>
          <CardStory>
            <img src={storyImage} alt="Storie" />
            <CardStoryControls>
              <CardStoryTap src={playIcon} alt="Clique para começar o video" />
              <span>30 segs</span>
            </CardStoryControls>
      
          
          </CardStory>
        </BannerRightContainer>

      </div>
    </BannerContent>
  )
}

export default Services