import styled from "styled-components";
import { COLORS } from '../../global/styles'

export const ServiceCTA = styled.a`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: ${COLORS.white};
    text-transform: uppercase;
`;

export const ServiceContent = styled.div`
    background: ${COLORS.generalGradient};
    padding: 100px 0px;

    @media (max-width: 768px) {
        padding: 50px 0px;
    }
`;

export const ServiceLeftContent = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const ServiceRightContent = styled.div`
    display:grid;
    grid-template-columns: auto auto;
    grid-gap: 40px;


    @media (max-width: 768px) {
        grid-template-columns: auto;
        margin-top: 30px;
    }
`;

export const ServiceBox = styled.a`
    width: 215px;
    height: 230px;
    background: rgba(255, 255, 255, 0.67);
    box-shadow: 20px 26px 1px rgba(0, 0, 0, 0.11);
    background: url(${props => props.image});
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 25px;
    transition: 0.5s all;

    :nth-child(3n), :first-child{
        top: -40px;   
    }
    
    ::after{
        content: "";
        position: absolute;
        top: 0px;
        width: 100%;
        background: ${COLORS.white};
        height: 100%;
        left: 0px;
        opacity: .7;
    }

    :hover{
        transform: scale(1.1);
    }

    @media (max-width: 768px) {
        top: 0px !important;
        width: 100%;
        height: 160px;
    }
`;

export const BoxTitle = styled.div`
    color: ${COLORS.black};
    margin-bottom: 11px;
    font-weight: 700;
    z-index: 1;
`;

export const BoxDescripion = styled.div`
    color: ${COLORS.black};
    z-index: 1;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
`;

export const BoxLink = styled.p`
    color: ${COLORS.primary};
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    left: 34px;
    bottom: 20px;
    z-index: 1;

    @media (max-width: 768px) {
       display: none;
    }
`;

