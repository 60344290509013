import React, { useCallback } from "react";
import { items } from './data'

import { MainSubtitle, MainTitle } from '../../global/components'

import {
  PortfolioContent, PortBox, PortClient, PortTitle,
  PortfolioBoxes, TapIcon, PortImage
} from './styles'

import {
  LightgalleryProvider,
  LightgalleryItem,
} from "react-lightgallery";

import tapicon from '../../assets/icons/tap.png'

const PhotoItem = ({ image, group }) => (
  <div style={{ display: "none" }}>
    <LightgalleryItem group={group} src={image} thumb={image} ></LightgalleryItem>
  </div>

);


function Portfolio() {

  return (
    <PortfolioContent id="portfolio">
      <div className='center'>
        <MainSubtitle>Veja o meu</MainSubtitle>
        <MainTitle useIcon={true}>Portfólio</MainTitle>
      </div>

      <div className='center flex flex-column'>
        <br /><br /><br />
        <LightgalleryProvider>
          <PortfolioBoxes>
            {
              items.map((item, index) => {
                return (
                  <>
                    <LightgalleryItem group={item.id} src={item.image} >
                      <PortBox key={index}>
                        <PortImage src={item.thumbnail} alt="Icone pointer" />
                        <PortTitle>{item.title}</PortTitle>
                        <PortClient>{item.client}</PortClient>
                        <TapIcon src={tapicon} />
                      </PortBox>
                    </LightgalleryItem>

                    {item.images.map((photo, idx) => (
                      <PhotoItem key={idx} image={photo} group={item.id} />
                    ))}
                  </>

                )
              })
            }

          </PortfolioBoxes>
        </LightgalleryProvider>
      </div>
    </PortfolioContent>
  )
}

export default Portfolio