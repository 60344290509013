import styled from "styled-components";
import { COLORS } from '../../global/styles'

import quoteLeft from '../../assets/icons/quote-left.png'
import quoteRight from '../../assets/icons/quote-right.png'


export const TestimonialContent = styled.div`
    background: ${COLORS.generalGradient};
    padding: 50px 0px;

    @media (max-width: 768px) {
        padding: 50px 0px 30px 0px;
    }
`;

export const TestimonialBox = styled.div`
    margin: 80px 0px;
    display: flex;

    @media (max-width: 768px) {
        flex-direction:column;
        justify-content: center; 
        align-items: center;
        margin: 50px 0px;
    }
`;

export const TestImage = styled.div`
    width: 170px;
    height: 170px;
    background: rgba(255, 255, 255, 0.37);
    border: 1px solid ${COLORS.white};
    box-shadow: 3px 4px 1px rgba(0, 0, 0, 0.17);
    border-radius: 50%;
    display:flex;
    padding: 5px;
    position: relative;

    ::before{
        content: "";
        background: url(${quoteLeft});
        position: absolute;
        height: 64px;
        width: 64px;
        top: -17px;
        left: -21px;
    }

    ::after{
        content: "";
        background: url(${quoteRight});
        position: absolute;
        height: 64px;
        width: 64px;
        bottom: -17px;
        right: -15px;
    }

    img{
        z-index:1;
    }


    @media (max-width: 768px) {

        img{    
            width: 100%;
        }
    }
    
`;

export const TestContent = styled.div`
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
        margin-top: 30px;
        margin-left: 0px;
        padding: 0px 20px;
    }
`;

export const TestTitle = styled.p`
    font-weight: 700;
    font-size: 19px;
    line-height: 35px;
    color: ${COLORS.white};

    @media (max-width: 768px) {
       text-align: center;
    }
`;

export const TestDate = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.white};
    margin-bottom: 15px;

    @media (max-width: 768px) {
       text-align: center;
    }
`;


export const TestArrows = styled.div`
    position: absolute;

    img{
        cursor: pointer;
    }
`;



export const TestTitleBlue = styled.h2`
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: ${COLORS.white};
    text-transform: uppercase;

    strong{
        font-weight: 500;
    }


   
`;