import styled from "styled-components";
import { COLORS } from '../../global/styles'




export const PortfolioContent = styled.div`
    display: flex;
    padding: 60px 0px;
    flex-direction: column;
`;

export const PortfolioBoxes = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 230px);
    grid-gap: 30px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 100%);
    }
`;

export const PortImage = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
    
    @media (max-width: 768px) {
        height: 300px;
    }
`;


export const PortBox = styled.div`
    background: #FFFFFF;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: 0.5s all;

    :hover{
        transform: scale(1.04);
    }
`;

export const PortTitle = styled.div`
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: ${COLORS.black};
    margin-top: 10px;
    text-align: center;
    margin-bottom: 13px;
`;

export const PortClient = styled.div`
    font-weight: 300;
    font-size: 13px;
    line-height: 12px;
    color: ${COLORS.black};
    margin-bottom: 8px;
`;

export const TapIcon = styled.img`
    width: 30px !important;
    position: absolute;
    height: 30px;
    left: 20px;
    top: 20px;
`;

