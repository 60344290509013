import React from 'react'
import { MenuButton, MenuLink, HeaderContent, MenuContainer } from './styles'
import imageLogo from '../../assets/images/main-logo.png'


function Header() {
  return (
    <HeaderContent>
      <div className='center flex space-between'>
        <img src={imageLogo} alt="Janderson Vidros Logo" />
        <MenuContainer className='flex'>
          <MenuLink href="#servicos">Serviços</MenuLink>
          <MenuLink href="#portfolio">Portifólio</MenuLink>
          <MenuLink href="#duvidas">Dúvidas</MenuLink>
          <MenuButton href="/#inscreva-se">Faça um Orçamento</MenuButton>
        </MenuContainer>
      </div>
    </HeaderContent>
  )
}

export default Header