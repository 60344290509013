import React, { useState } from 'react'
import swal from 'sweetalert'
import { useForm, Controller } from 'react-hook-form'

import {
  GeneralTitleBlue, GeneralRegularText, GeneralFormButton,
  GeneralFormGroup, GeneralLabel, GeneralInput, GeneralCheckBox,
  GeneralCheckLabel, GeneralTextArea, GeneralCheckFormGroup,
  GeneralFormError
} from '../../global/components'

import {
  BudgetFormContent, FormLeftContent, FormRightContent,
  FormBudget
} from './styles'

import InputMaskCustom from '../../global/utils'

import mirrorIcon from '../../assets/icons/mirror-form.png'

import { postContactForm } from '../../api/api'

function BudgetForm() {
  const CLIENT_ID = 1 //Janderson Vidros
  const maxLengthDefault = { value: 200, message: "Limite máximo de 200 caracteres." }
  const { setError, register, formState: { errors }, reset, handleSubmit, control, clearErrors } = useForm();

  const [sendingForm, setSendingForm] = useState(false);

  const onSubmit = data => {
    setSendingForm(true)

    data["client_id"] = CLIENT_ID
    setTimeout(() => {
      postContactForm(data)
        .then(() => {
          setSendingForm(false)
          swal("Tudo certo!", "Seu contato foi recebido com sucesso.", "success")
          reset()
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).map((item) => {
            setError(item.toLowerCase(), { type: "server", message: error.response.data.errors[item][0] }, { shouldFocus: true })
          })
        })
        .finally(() => {
          setSendingForm(false)
        });

    }, 2000);

  };

  return (
    <BudgetFormContent id="inscreva-se">
      <div className='center flex'>
        <FormLeftContent>
          <img src={mirrorIcon} alt="Icon Janderson Vidros" />
          <GeneralTitleBlue><strong>Quero um </strong><br />orçamento</GeneralTitleBlue>
          <br />
          <GeneralRegularText>
            Deixe seu contato e <br />
            sua mensagem se desejar. <br />
            Logo entraremos em contato <br />
            com você!
          </GeneralRegularText>
        </FormLeftContent>

        <FormRightContent>
          <FormBudget onSubmit={handleSubmit(onSubmit)}>
            <GeneralFormGroup>
              <GeneralLabel>Nome</GeneralLabel>
              <GeneralInput placeholder='Ex: Janderson Messias'
                {...register("name",
                  {
                    required: "Campo obrigatório",
                    maxLength: maxLengthDefault
                  })
                }
              />
              {errors.name && <GeneralFormError>{errors.name?.message}</GeneralFormError>}
            </GeneralFormGroup>
            <br /><br />
            <GeneralFormGroup>
              <GeneralLabel>Celular ou WhatsApp</GeneralLabel>
              <Controller
                control={control}
                {...register("phone",
                  {
                    required: "Campo obrigatório",
                    maxLength: maxLengthDefault
                  })
                }
                render={({ field: { onChange, onBlur, ref } }) => (
                  <InputMaskCustom
                    mask="(99) 99999-9999"
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}

                  />
                )}
              />
              {errors.phone && <GeneralFormError>{errors.phone?.message}</GeneralFormError>}
            </GeneralFormGroup>
            <br /><br />
            <GeneralFormGroup>
              <GeneralLabel>Mensagem</GeneralLabel>
              <GeneralTextArea placeholder='Descreva resumidamente o que você precisa'
                {...register("message",
                  {
                    required: "Campo obrigatório",
                    maxLength: maxLengthDefault
                  })
                }
              />
              {errors.message && <GeneralFormError>{errors.message?.message}</GeneralFormError>}
            </GeneralFormGroup>
            <br /><br />
            <GeneralCheckFormGroup>
              <GeneralCheckBox type="checkbox" id="policy"
                {...register("terms",
                  {
                    required: "Campo obrigatório"
                  })
                }
              />
              <GeneralCheckLabel for="policy">
                Autorizo que entrem em contato comigo via whatsapp
                ou telefone para solicitar mais informações e me enviarem os
                valores do orçamento.
              </GeneralCheckLabel>
       
            </GeneralCheckFormGroup>
            {errors.terms && <GeneralFormError>{errors.terms?.message}</GeneralFormError>}
            <br /><br />

            {!sendingForm && <GeneralFormButton type="submit">Enviar</GeneralFormButton>}
            {sendingForm && <GeneralFormError>Enviando...</GeneralFormError>}
          </FormBudget>
        </FormRightContent>
      </div>
    </BudgetFormContent>
  )
}

export default BudgetForm