import styled from 'styled-components'
import { COLORS } from '../../global/styles'

import arrow from '../../assets/icons/accordion-arrow.png'

export const FAQContainer = styled.div`
    padding: 80px 0px;

    @media (max-width: 768px) {
        padding: 50px 0px;
    }

`;

export const FAQContent = styled.div`
    display:flex;
    align-items: center;    
    flex-direction: column;
`;

export const FAQList = styled.div`
    margin-top: 50px; 
    width: 100%;
`;

export const FAQItem = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 10px;

    ::after{
        content: "";
        background: url(${arrow});
        position: absolute;
        height: 20px;
        width: 20px;
        top: 15px;
        right: 16px;
        transform-origin: center;
        transform: ${props => props.active ? "rotate(0deg)" : "rotate(180deg)" }
    }
`;

export const FAQTitle = styled.p`
    border: 1px solid #00000021;
    border-radius: 2px;
    color: ${COLORS.black};
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 36px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

export const FAQDescription = styled.p`
    padding: 25px 36px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${COLORS.black};
    display: ${props => props.active ? "block" : "none" };

    @media (max-width: 768px) {
        font-size: 14px;
    }

`;
