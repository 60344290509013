import styled from "styled-components";
import { COLORS } from '../../global/styles'
import linhasImage from '../../assets/images/linhas.png'

export const BannerContent = styled.div`
    padding: 50px 0px;
    
    @media (max-width: 768px) {
        padding: 20px 0px 50px 0px;;
    }
`;

export const BannerLeftContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-evenly;

    @media (max-width: 768px) {
        height: 350px;
    }

`;

export const BannerTitle = styled.p`
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    background: radial-gradient(216.22% 216.22% at 25.05% 0%, ${COLORS.primary} 0%, ${COLORS.primary} 29.69%, ${COLORS.black} 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px) {
        font-size: 23px;
        line-height: 28px;
        width: 100%;
    }

    ::after{
        content: "";
        position: relative;
        display: block;
        border-bottom: 2px solid ${COLORS.primary};
        border-bottom-style: dashed;
        width: 150px;
        margin: 0 auto;
        top: 30px;
    }

    @media (max-width: 768px) {
        ::after{
            top: 20px;
        }
    }
`;

export const BannerNumbersContainer = styled.div`
    display:flex;
    justify-content: space-evenly;
    gap: 40px;
`;

export const Number = styled.div`
    display:flex;
    align-items:center;
    gap: 5px;

    p{
        font-size: 13px;
        line-height: 12px;
    }

    span{
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        color: ${COLORS.black};
    }

    @media (max-width: 768px) {
        span{
            font-size: 30px;
        }
    }
`;

export const BannerRightContainer = styled.div`
    @media (max-width: 768px) {
        display:flex;
        justify-content:center
    }
`;

export const CardStory = styled.div`
    background: ${COLORS.white};
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.08);
    padding: 10px;
    position: relative;
    cursor: pointer;
    
    @media (max-width: 768px) {
        height: 340px;

        img{
            height: 100%;
        }
    }

    img{
        border-radius: 10px;
    }
    
    ::before{
        content: "";
        background: url(${linhasImage});
        position: absolute;
        display: block;
        width: 300px;
        height: 300px;
        top: -35px;
        z-index: -1;
        left: -108px;
    }

    ::after{
        content: "";
        background: url(${linhasImage});
        position: absolute;
        display: block;
        width: 300px;
        height: 300px;
        bottom: -35px;
        z-index: -1;
        right: -50px;
        transform: rotate(177deg);
    }

    @media (max-width: 768px) {
        ::after, ::before{
            display:none;
        }
    }
`;
export const CardStoryControls = styled.div`
    position: absolute;
    top: 30px;
    left: 30px;
    color: ${COLORS.white};
    font-weight: 700;
    align-items: center;
    display:flex;
`

export const CardStoryTap = styled.img`
    width: 30px;
    margin-right: 10px;
`