import React, { useState } from 'react'
import swal from 'sweetalert'
import { useForm, Controller } from 'react-hook-form'

import {
  GeneralLabel, GeneralInput, GeneralFormButton,
  GeneralFormGroup, MainSubtitle, GeneralFormError
} from '../../global/components'

import {
  BudgetForm, BudgetInner, PortBudgetContent,
  BudgetTitle, BudgetFormInner
} from './styles'

import InputMaskCustom from '../../global/utils'

import { postContactForm } from '../../api/api'

function PortfolioBudget() {
  const CLIENT_ID = 1 //Janderson Vidros
  const maxLengthDefault = { value: 200, message: "Limite máximo de 200 caracteres." }
  const { setError, register, formState: { errors }, reset, handleSubmit, control } = useForm();

  const [sendingForm, setSendingForm] = useState(false);

  const onSubmit = data => {
    setSendingForm(true)
    data["client_id"] = CLIENT_ID
    setTimeout(() => {
      postContactForm(data)
        .then(() => {
          setSendingForm(false)
          swal("Tudo certo!", "Seu contato foi recebido com sucesso.", "success")
          reset()
        })
        .catch((error) => {
          Object.keys(error.response.data.errors).map((item) => {
            setError(item.toLowerCase(), { type: "server", message: error.response.data.errors[item][0] }, { shouldFocus: true })
          })
        })
        .finally(() => {
          setSendingForm(false)
        });

    }, 2000);

  };

  return (
    <>

      <PortBudgetContent>
        <div className='center flex flex-column'>
          <BudgetInner>
            <div>
              <MainSubtitle color="#fff">Gostou?</MainSubtitle>
              <BudgetTitle useIcon={true} color="#fff">
                <strong>Solicite um </strong><br />
                Orçamento
              </BudgetTitle>
            </div>

            <BudgetForm onSubmit={handleSubmit(onSubmit)}>
              <BudgetFormInner>
                <GeneralFormGroup>
                  <GeneralLabel>Nome</GeneralLabel>
                  <GeneralInput placeholder='Ex: Janderson Messias'
                    {...register("name",
                      {
                        required: "Campo obrigatório",
                        maxLength: maxLengthDefault
                      })
                    }
                  />
                  {errors.name && <GeneralFormError>{errors.name?.message}</GeneralFormError>}
                </GeneralFormGroup>
                <GeneralFormGroup>
                  <GeneralLabel>Celular ou WhatsApp</GeneralLabel>
                  <Controller
                    control={control}
                    {...register("phone",
                      {
                        required: "Campo obrigatório",
                        maxLength: maxLengthDefault
                      })
                    }
                    render={({ field: { onChange, onBlur, ref } }) => (
                      <InputMaskCustom
                        mask="(99) 99999-9999"
                        onBlur={onBlur}
                        onChange={onChange}
                        inputRef={ref}

                      />
                    )}
                  />
                  {errors.phone && <GeneralFormError>{errors.phone?.message}</GeneralFormError>}
                </GeneralFormGroup>
              </BudgetFormInner>

              {!sendingForm && <GeneralFormButton type="submit">Enviar</GeneralFormButton>}
              {sendingForm && <GeneralFormError>Enviando...</GeneralFormError>}
            </BudgetForm>

          </BudgetInner>
        </div>
      </PortBudgetContent>
    </>
  )
}

export default PortfolioBudget