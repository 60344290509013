import styled from "styled-components";
import { COLORS } from '../../global/styles'


export const MenuLink = styled.a`
    font-size: 16px;
    font-weight: 600;
    line-height: 12px;
    color: ${COLORS.textColor};
`;

export const MenuButton = styled.a`
    font-size: 11px;
    font-weight: 600;
    display:flex;
    justify-content: center;
    align-items: center;
    background: ${COLORS.primary};
    height: 35px;
    color: ${COLORS.white};
    text-transform: uppercase;
    width: 200px;
    transition: 0.5s all;
    cursor: pointer;

    :hover{
        transform: scale(1.05);
    }

`;

export const HeaderContent = styled.header`
    display:flex;
    height: 95px;
    align-items:center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    

    img{
        height: 58px;
        width: 221px;
    }

    @media (max-width: 768px) {
        div:first-child{
            justify-content:center;
            align-items: center;
        }
    }
`;

export const MenuContainer = styled.div`
    display:flex;
    align-items: center;
    gap: 40px;

    @media (max-width: 768px) {
        display:none;
    }
`;
