import React from 'react'

import { GeneralTitleBlue, GeneralRegularText } from '../../global/components'
import {
  ServiceCTA, ServiceContent, ServiceLeftContent,
  ServiceRightContent, ServiceBox, BoxTitle, BoxDescripion,
  BoxLink
} from './styles'

import bgBath from '../../assets/images/bg-bathroom.jpg'
import bgBathWindow from '../../assets/images/bg-bath-window.png'
import bgDoor from '../../assets/images/bg-door.png'
import bgGuardaCorpo from '../../assets/images/bg-guarda-corpo.jpg'

function Services() {

  const ServiceList = [
    {
      image: bgBath,
      title: "Boxes",
      description: "Box em formatos em L e frontal."
    },
    {
      image: bgGuardaCorpo,
      title: "Guarda-Corpo",
      description: "Para escadas, sacadas e outros."
    },
    {
      image: bgBathWindow,
      title: "Básculas",
      description: "Para banheiros, deixando o ambiente mais iluminado e agradável."
    },
    {
      image: bgDoor,
      title: "Portas e Janelas",
      description: "Em todos os formatos e tamanhos."
    },
  ]

  return (
    <ServiceContent id="servicos">
      <div className='center flex space-between'>
        <ServiceLeftContent>
          <GeneralTitleBlue useBorder={true}>
            <strong>CONHEÇA OS </strong><br />
            SERVIÇOS
          </GeneralTitleBlue>
          <br /> <br /> <br /> <br />
          <GeneralRegularText>
            Confira ao lado alguns dos meus 
            principais serviços
          </GeneralRegularText>
          <br />
          <ServiceCTA href="#portfolio">Veja meus serviços feitos</ServiceCTA>
        </ServiceLeftContent>

        <ServiceRightContent>
          {
            ServiceList.map((item, index) => {
              return (
                <ServiceBox key={index} image={item.image} href="/#inscreva-se">
                  <BoxTitle>{item.title}</BoxTitle>
                  <BoxDescripion>{item.description}</BoxDescripion>
                  <BoxLink >Consultar Modelos</BoxLink>
                </ServiceBox>
              )
            })
          }
        </ServiceRightContent>
      </div>
    </ServiceContent>
  )
}

export default Services