import styled from 'styled-components'
import { COLORS } from '../../global/styles'


export const BudgetFormContent = styled.div`
    background: ${COLORS.generalGradient};
    padding: 100px 0px;

    @media (max-width: 768px) {
        padding: 50px 20px 50px 20px;
    }
`;

export const FormLeftContent = styled.div`
    border-right: 2px solid #ffffff82;
    border-right-style: dashed;
    margin-right: 100px;
    width: 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    img{
        width: 99px;
        height: 63px;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        width: 100%;
        border-right: 0px;
    }
`;

export const FormRightContent = styled.div`
    width: 35%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const FormBudget = styled.form`
    @media (max-width: 768px) {
        button{
            width: 100%;
        }   
    }
`;

