import React, { useState } from 'react'

import { MainTitle, MainSubtitle, GeneralButton } from '../../global/components'

import {
  FAQContainer, FAQContent, FAQList,
  FAQItem, FAQTitle, FAQDescription
} from './styles'


function FAQ() {

  const [open, setOpen] = useState(-1)

  const faqs = [
    { title: "Como é feito o orçamento?", description: "O orçamento é feito totalmente sem custo, o vidraceiro profissional vai até o local da instalação para realizar as medições necessárias e com o equipamento adequado. Ele fará os cálculos e enviará o orçamento posteriormente via telefone ou e-mail. " },
    { title: "Posso dividir o pagamento?", description: "Sim, você pode dividir o valor dos vidros em até 10x, porém o pagamento da mão de obra é somente à vista após o término da montagem." },
    { title: "Como funciona a compra dos vidros?", description: "Não cobramos nenhum centavo a mais no valor dos vidros! O valor cobrado é a preço de custo da distribuidora de vidros e fazemos o orçamento em diversas distribuidoras da região e repassamos o menor preço." },
    { title: "Como é o processo de instalação?", description: "Após o fechamento da obra, será combinado com o cliente o melhor dia e horário para que possamos fazer a instação. E quanto necessário, também trabalhamos aos sabados e domingos." },
    { title: "Qual o tempo de validade do orçamento?", description: "Normalmente um orçamento feito é válido por 7 dias. Isso ocorre porque o preço dos vidros nas distribuidoras possui variação constantemente, o portanto faz o valor final mudar. Vale ressaltar que o valor da mão de obra não se altera caso o cliente decida fechar depois dos 7 dias, basta entrar em contato e será informado o novo valor total dos vidros devido ao reajuste." },
  ]


  return (
    <FAQContainer id="duvidas">
      <div className="center-inner text-center">
        <MainSubtitle>Mas se ainda tem</MainSubtitle>
        <MainTitle textAlign="center">Dúvidas</MainTitle>
      </div>

      <FAQContent className='center-inner'>
        <FAQList>
          {
            faqs.map((item, index) => {
              return (
                <FAQItem active={index == open} key={index} onClick={() => index == open ? setOpen(-1) : setOpen(index)}>
                  <FAQTitle>{item.title}</FAQTitle>
                  <FAQDescription active={index == open}>{item.description}</FAQDescription>
                </FAQItem>
              )
            })
          }
        </FAQList>
        <br /> <br /> <br /> <br /> <br />
        <GeneralButton href="/#inscreva-se">Faça um Orçamento</GeneralButton>
      </FAQContent>


    </FAQContainer>
  )
}

export default FAQ