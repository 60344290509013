import React from 'react'
import InputMask from 'react-input-mask';
import { COLORS } from '../global/styles';

export default function InputMaskCustom(props) {
	return (
		<InputMask
			mask={props.mask}
			value={props.value}
			onChange={props.onChange}
			style={styleInput}
			placeholderChar={'\u2000'}
			placeholder='(99) 99999-9999'
		/>
	)
}
const styleInput = {
    background: `${COLORS.white}`,
    borderRadius: '6px',
    border: 'none',
    height: '35px',
    fontSize: '12px',
    width: '100%',
    padding: '0px 10px',
}